footer {
    background: $clr-primary;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;

    a {
        color: $clr-bg;
    }

    .footer-logo {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 2rem;
        display: inline-block;
    }

    .permalinks {
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        margin: 0 auto 3rem;
    }

    .footer-socials {
        justify-content: center;
        gap: 1rem;
        margin-bottom: 4rem;

        a {
            background: $clr-bg;
            color: $clr-white;
            padding: 0.8rem;
            border-radius: 0.7rem;
            display: flex;
            border: 1px solid transparent;

            &:hover {
                background: transparent;
                color: $clr-bg;
                border-color: $clr-bg;
            }
        }
    }

    .footer-copyright {
        margin-bottom: 4rem;
        color: $clr-bg;
    }
}