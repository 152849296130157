.review-list {
    perspective: 250px;
    -moz-perspective: 250px;
    width: 400px;
    height: 200px;
    top: 50px;
    position: relative;
    margin: 0 auto;
}

@keyframes efeitoCarrosel {
    from {
        -webkit-transform: rotateY(360deg) translateZ(130px) rotateY(-360deg);
        -moz-transform: rotateY(360deg) translateZ(130px) rotateY(-360deg);
        z-index: 10;
        opacity: 1
    }

    50% {
        z-index: -10;
    }

    to {
        -webkit-transform: rotateY(0deg) translateZ(130px) rotateY(0deg);
        -moz-transform: rotateY(0deg) translateZ(130px) rotateY(0deg);
        z-index: 10;
        opacity: 1
    }
}

@-moz-keyframes efeitoCarrosel {
    from {
        -moz-transform: rotateY(360deg) translateZ(130px) rotateY(-360deg);
        z-index: 10;
        opacity: 1
    }

    50% {
        z-index: -10;
    }

    to {
        -moz-transform: rotateY(0deg) translateZ(130px) rotateY(0deg);
        z-index: 10;
        opacity: 1
    }
}

.caixa__card {
    width: 90px;
    height: 100px;
    background-color: #442222;
    position: absolute;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    top: 35px;
    left: 125px;
    font-size: 9px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .5);
    text-align: center;
    animation-name: efeitoCarrosel;
    -moz-animation-name: efeitoCarrosel;
    animation-duration: 8s;
    -moz-animation-duration: 8s;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

.caixa__card:hover {
    border: solid #4466cc 3px;
    box-shadow: 0px 0px 10px #4466cc;
}

// .caixa__card.cc__1 {
//     background: linear-gradient(-229deg, #0093E9, #80D0C7);
//     animation-delay: -7s;
//     -moz-animation-delay: -7s;
// }

// .caixa__card.cc__2 {

//     background: linear-gradient(-229deg, #00DBDE, #FC00FF);
//     animation-delay: -6s;
//     -moz-animation-delay: -6s;
// }

// .caixa__card.cc__3 {

//     background: linear-gradient(-229deg, #50d990, #d4d054);
//     animation-delay: -5s;
//     -moz-animation-delay: -5s;
// }

// .caixa__card.cc__4 {
//     background: linear-gradient(-229deg, #cf91ff, #5782F5);
//     animation-delay: -4s;
//     -moz-animation-delay: -4s;
// }

// .caixa__card.cc__5 {
//     background: linear-gradient(-229deg, #642B73, #C6426E);
//     animation-delay: -3s;
//     -moz-animation-delay: -3s;
// }

// .caixa__card.cc__6 {
//     background: linear-gradient(-229deg, #D9AFD9, #97D9E1);
//     animation-delay: -2s;
//     -moz-animation-delay: -2s;
// }

// .caixa__card.cc__7 {
//     background: linear-gradient(-229deg, #FBAB7E, #F7CE68);
//     animation-delay: -1s;
//     -moz-animation-delay: -1s;
// }

// .caixa__card.cc__8 {
//     background: linear-gradient(-229deg, #1472ff, #A8EB12);
// }