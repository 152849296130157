header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    margin-bottom: 4rem;

    .header-container {
        text-align: center;
        height: 100%;
        position: relative;
    }

    .cta {
        margin-top: 2.5rem;
        gap: 1.2rem;
        justify-content: center;
    }

    .header-socials {
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 0;
        bottom: 3rem;

        &::after {
            content: "";
            width: 1px;
            height: 2rem;
            background: $clr-primary;
        }
    }

    .me {
        width: 22rem;
        height: 30rem;
        // background-color: $clr-primary;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        padding: 5rem 1.5rem 1.5rem 1.5rem;

        img {
            border-radius: 12rem 12rem 0 0;
            overflow: hidden;
        }
    }

    .scroll-down {
        position: absolute;
        right: -2.5rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 0.9rem;
    }

}