.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 4rem;

    .portfolio-item {
        background: $clr-bg-variant;
        padding: 1.2rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: $transition;

        &:hover {
            border-color: $clr-primary-variant;
            background: transparent;
        }

        .portfolio-item-img {
            height: 9.5rem;
            border-radius: 1.5rem;
            overflow: hidden;
        }

        h3 {
            margin: 1.2rem 0 2rem;
        }

        .portfolio-item-cta {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
        }
    }
}