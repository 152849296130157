// COLOR PALETTE

$clr-bg: #1f1f38;
$clr-bg-variant: #2c2c6c;
$clr-primary: #4db5ff;
$clr-primary-variant: rgb(77, 181, 255, 0.4);
$clr-white: #fff;
$clr-light: rgb(255, 255, 255, 0.6);

$container-width-lg:75%;
$container-width-md:86%;
$container-width-sm:90%;

$transition:all 400ms ease;

// LAYOUT
$layoutPadding: 20px;

// LAYOUT BREAKPOINTS
$break-narrow: 1200px;
$break-normal: 1360px;
$break-wide: 1920px;

$padded-break-narrow: $break-narrow+$layoutPadding * 2;
$padded-break-normal: $break-normal+$layoutPadding * 2;
$padded-break-wide: $break-wide+$layoutPadding * 2;