::selection {
    background-color: $clr-bg;
    color: $clr-bg-variant;
}

* {
    box-sizing: border-box;
}

html {
    font-family: Roboto, sans-serif;
    line-height: 1.8;
    line-height: 1.5;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    width: 100%;
    height: 100%;
    background-color: $clr-bg;
    color: $clr-white;
    line-height: 1.7;
    font-family: 'Poppins', sans-serif;
}

.container {
    width: $container-width-lg;
    margin: 0 auto;
}

section {
    margin-top: 8rems;
    // height: 100vh;
}

section>h2,
section>h5 {
    text-align: center;
    color: $clr-light;
}

section>h2 {
    color: $clr-primary;
    margin-bottom: 3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin: 0 0 1rem;
    line-height: lh(40px, 29px);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: em(16px);
    margin: em(63px) 0;
    grid-area: title;
    color: $clr-bg;
    text-transform: uppercase;
}

h3 {
    font-size: em(29px);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.text-component {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid transparent;
    padding: 0 2px;
}

.btn {
    --loader-padding: 8px;
    outline: none;
    border: none;
    height: auto;
    border-radius: 2px;
    cursor: pointer;
    white-space: nowrap;
    transition: 50ms transform, .1scubic-bezier(0, 0, 0.35, 1) min-width;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    --element-width: 32;
    --element-height: 32;
}

p {
    margin: 0 0 1em;
    max-width: 60ch;

    .main-text & {
        grid-column: 3 / 6;
        font-size: rem(22px);
        line-height: 1.384615;
        padding-inline-start: rem(30px);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 8px;
            height: 100%;
            max-height: 42px;
            background-color: $clr-bg;

        }


    }
}

button {
    cursor: pointer;
}

a {
    color: $clr-bg;

    &.active {
        background-color: $clr-bg;
        color: $clr-bg-variant;
    }
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

mark {
    color: $clr-bg;
    background-color: transparent;
}

input {
    border: none;
}

input:focus {
    outline: none;
}