 .container.contact-container {
     width: 58%;
     display: grid;
     grid-template-columns: 30% 58%;
     gap: 12%;

     .contact-options {
         gap: 1.2rem;

         .contact-option {
             background: $clr-bg-variant;
             padding: 1.2rem;
             border-radius: 1.2rem;
             text-align: center;
             border: 1px solid transparent;
             transition: $transition;

             &:hover {
                 background: transparent;
                 border-color: $clr-primary-variant;
             }

             .contact-option-icon {
                 font-size: 1.5rem;
                 margin-bottom: 0.5rem;
             }

             a {
                 margin-top: 0.7rem;
                 display: inline-block;
                 font-size: 0.8rem;
             }
         }
     }

     form {
         gap: 1.2rem;

         input,
         textarea {
             width: 100%;
             padding: 1.5rem;
             border-radius: 0.5rem;
             background: transparent;
             border: 2px solid $clr-primary-variant;
             resize: none;
             color: $clr-white;

             :focus {
                 border-color: $clr-primary-variant;
             }
         }



        //  button {
        //      position: relative;
        //      width: 70px;
        //      height: 30px;
        //      font-size: 10px;
        //      background-color: #202231;
        //      color: $clr-white;
        //      border: none;
        //      border-radius: 4px;
        //      cursor: pointer;
        //      transition: width .5s, border-radius .5s;

        //     //  &* {
        //     //      position: absolute;
        //     //      top: 50%;
        //     //      left: 50%;
        //     //      transform: translate(-50%, -50%);
        //     //      transition: opacity .25s;
        //     //  }

        //  }

        //  .active {
        //      width: 30px;
        //      background-color: #44c08a;
        //      border-radius: 50%;

        //      p {
        //          display: none;
        //      }

        //      .icon {
        //          display: block;
        //      }
        //  }


        //  .icon {
        //      display: none;
        //      transition-delay: .5s;
        //  }

     }
 }