.services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    .service {
        background: $clr-bg-variant;
        border-radius: 0 0 2rem 2rem;
        border: 1px solid $clr-primary;
        height: fit-content;
        transition: $transition;

        &:hover {
            background: transparent;
            border-color: $clr-primary-variant;
            cursor: default;
        }

        .service-header {
            background: $clr-primary;
            padding: 2rem;
            border-radius: 0 0 2rem 2rem;
            box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);

            h3 {
                color: $clr-bg;
                font-size: 1rem;
                text-align: center;
            }
        }

        .service-list {
            padding: 1rem;

            li {
                display: flex;
                gap: 1rem;
                margin-bottom: 0.8rem;
            }

            .service-list-icon {
                color: $clr-primary;
                margin-top: 2px;
            }

            .p {
                font-size: 0.9rem;
            }
        }
    }
}